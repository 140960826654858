import { Button, Collapse, Select, DatePicker } from "antd";
import React from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

import { Eye, Note } from "../../../../../assets/svg";
import "../../../../../assets/styles/tableStyle.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { AiOutlineForm } from "react-icons/ai";
import { GetWorkSite } from "../../../../../helper/worksite/worksite";
import { useEffect, useCallback, useRef } from "react";
import { PayRollService } from "../../../../../config/axiosUrl";
import moment from "moment";

import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import * as XLSX from "xlsx";

const { Panel } = Collapse;

const { RangePicker } = DatePicker;

const DateFormat = "yyyy-MM-DD";

const getRange = () => {
  const nearestSat = moment().isoWeekday(6);
  const nearestFri = moment().isoWeekday(5);

  if (nearestFri.isBefore(nearestSat)) {
    return {
      end: nearestFri.format(DateFormat),
      start: nearestSat.subtract(1, "week").format(DateFormat),
    };
  } else {
    return {
      end: nearestFri.format(DateFormat),
      start: nearestSat.format(DateFormat),
    };
  }
};

const get10Range = (startDate, endDate) => {
  let range = [],
    start = moment(startDate),
    end = moment(endDate);

  while (range.length < 10) {
    start = start.subtract(1, "week");
    end = end.subtract(1, "week");
    range.push({
      label: `From ${start.format(DateFormat)} to ${end.format(DateFormat)}`,
      value: `${start.format(DateFormat)}/${end.format(DateFormat)}`,
    });
  }
  return range;
};

const TimesheetPayroll = () => {
  const tableRef = useRef();
  const navigate = useNavigate();

  const [timesheet, setTimesheet] = useState({
    data: [],
    loading: true,
    employmentStatus: [],
    isFirstLoad: true,
  });
  const [exportExcel, setExportExcel] = useState([]);

  const [filter, setFilter] = useState({
    worksites: [],
    payment: [],
    allWorksiteLoaded: false,
    selectedWorksite: "",
  });

  const [dateRange, setDateRange] = useState(() => {
    let defDate = { start: "", end: "", range: [] };

    defDate = getRange();
    defDate.range = [defDate.start];
    defDate.rangeOptions = [
      {
        label: `From ${defDate.start} to ${defDate.end}`,
        value: `${defDate.start}/${defDate.end}`,
      },
      ...get10Range(defDate.start, defDate.end),
    ];

    let curr = moment(defDate.start);

    while (moment(defDate.end).isAfter(curr)) {
      curr = curr.add(1, "day");
      defDate.range.push(curr.format(DateFormat));
    }

    return defDate;
  });

  const getTimesheet = useCallback(
    async (worksite_id = "", employment_status_id = []) => {
      await new Promise((res) => {
        setTimesheet((prev) => ({
          ...prev,
          loading: true,
        }));
        res(true);
      });

      let params = {
        worksite_id: Array.isArray(worksite_id)
          ? worksite_id[0] || ""
          : worksite_id || "",
        from: dateRange.start || "",
        to: dateRange.end || "",
      };

      PayRollService.post("/api/v1/setting/payroll/get-timesheet?dev=3", params)
        .then((res) => {
          if (res.status) {
            const combinedDays = res?.data?.data?.data?.reduce((acc, item) => {
              const days = item?.worksites?.[0]?.days || [];
              const jobNo = item?.worksites?.[0]?.job_no || null;
              const updatedDays = days.map((day) => ({
                ...day,
                job_No: jobNo,
              }));
              return acc.concat(updatedDays);
            }, []);

            const groupedDays = combinedDays.reduce((acc, curr) => {
              const key = curr.day; // Group by `day`
              if (!acc[key]) {
                acc[key] = {
                  ...curr,
                  worksite_timesheet_data: [...curr.worksite_timesheet_data],
                };
              } else {
                acc[key].worksite_timesheet_data = acc[
                  key
                ].worksite_timesheet_data.concat(curr.worksite_timesheet_data);
              }
              return acc;
            }, {});

            // Convert grouped object back to an array
            const mergedDaysArray = Object.values(groupedDays);

            setExportExcel(mergedDaysArray);
            setTimesheet((prev) => ({
              ...prev,
              data: res?.data?.data?.data || [],
              loading: false,
              employmentStatus: res?.data?.data?.employment_status,
              isFirstLoad: false,
            }));
          }
        })
        .catch((err) => {
          setTimesheet((prev) => ({
            ...prev,
            loading: false,
          }));
        });
    },
    [timesheet.isFirstLoad, dateRange]
  );

  const getPrevDateRange = function (e) {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      setDateRange((prev) => {
        const lastGenRange =
          prev.rangeOptions[prev.rangeOptions.length - 1].value.split("/");

        if (lastGenRange.length > 1) {
          prev.rangeOptions.push(
            ...get10Range(lastGenRange[0], lastGenRange[1])
          );
        }
        return { ...prev };
      });
    }
  };

  const onDateChange = function (value) {
    const dates = value.split("/");

    setDateRange((prev) => ({
      ...prev,
      start: dates[0],
      end: dates[1],
    }));
  };

  function horizontalScroller(e) {
    if (e.deltaY > 0) {
      tableRef.current.scrollLeft += 100;
      e.preventDefault();
    } else {
      tableRef.current.scrollLeft -= 100;
      e.preventDefault();
    }
  }

  const getWorksites = useCallback(
    async (search = "") => {
      const worksite = await GetWorkSite({
        search,
        take: 20,
        cursor: filter.worksites[filter.worksites.length - 1]?.id || "",
      });
      setFilter((prev) => {
        const allWork = prev.worksites.concat(worksite?.data?.data);
        const ids = [];
        const filtered = [];
        allWork.forEach((item) => {
          if (!ids.includes(item?.id)) {
            ids.push(item?.id);
            filtered.push(item);
          }
        });

        return {
          ...prev,
          worksites: filtered,
          allWorksiteLoaded: worksite?.extra?.totalItems <= filtered.length,
        };
      });
    },
    [filter.worksites]
  );

  const onScrollEnd = async (e) => {
    if (
      Math.round(e.target.scrollHeight - e.target.scrollTop) ===
        e.target.clientHeight &&
      !filter.allWorksiteLoaded
    ) {
      getWorksites();
    }
  };

  useEffect(() => {
    getTimesheet(filter.selectedWorksite || "" || []);
  }, [filter.selectedWorksite, , dateRange.start, dateRange.end]);

  useEffect(() => {
    getWorksites();
  }, []);

  const handleExportToExcel = () => {
    const headerRow = [
      "Emp. Co./Last Name",
      "Emp. First Name",
      "Payroll Category",
      "Job",
      "Cust. Co./Last Name",
      "Cust. First Name",
      "Notes",
      "Date",
      "Units",
      "Emp. Card ID",
      "Emp. Record ID",
      "Start/Stop Time",
      "Customer Card ID",
      "Customer Record ID",
    ];

    const dataRows = [];
    dataRows.push(headerRow);

    exportExcel?.forEach((item) => {
      const date = item.date || "-";
      const jobNo = item.job_No;
      item?.worksite_timesheet_data?.forEach((timesheetItem) => {
        const firstName = timesheetItem.worker_details?.[0]?.users?.[0]?.name;
        const lastName =
          timesheetItem.worker_details?.[0]?.users?.[0]?.last_name;
        const empId =
          timesheetItem.worker_details?.[0]?.users?.[0]?.employee_id;
        const payrollCat = timesheetItem?.payroll_category_name;
        const unit = timesheetItem?.unit;
        const cusLast = "-";
        const cusFirst = "-";
        const notes = "-";
        const recordId = "-";
        const startStop = "-";
        const cusCardId = "-";
        const cusRecord = "-";

        dataRows.push([
          lastName,
          firstName,
          payrollCat,
          jobNo,
          cusLast,
          cusFirst,
          notes,
          date,
          unit,
          empId,
          recordId,
          startStop,
          cusCardId,
          cusRecord,
        ]);
      });
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataRows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "FilteredTimesheetData");

    XLSX.writeFile(workbook, "filtered_timesheet_data.xlsx");
  };

  return (
    <div className="m-5 flex flex-col gap-7">
      <div className="bg-white p-8">
        <span className="flex justify-between">
          <h2 style={{ fontSize: "26px", fontWeight: "bold" }}>
            Timesheet Details
          </h2>

          <group className="flex gap-3">
            <Button
              className="flex items-center gap-1 border border-[#001C33]"
              onClick={() => navigate(`/admin/summary/:memory?`)}
            >
              <Eye
                style={{
                  scale: "80%",
                }}
              />
              Summarise Selection
            </Button>
            <Button
              className="border border-[#001C33]"
              onClick={handleExportToExcel}
            >
              Export to Excel
            </Button>
          </group>
        </span>
        <div className="flex gap-20 mt-16 mb-8">
          <span className="flex-1 flex flex-col">
            <label>Worksite</label>
            <Select
              onPopupScroll={onScrollEnd}
              onChange={(selectedWorksite) =>
                setFilter((prev) => ({ ...prev, selectedWorksite }))
              }
            >
              {filter.worksites.map((item) => (
                <Select.Option value={item?.id} key={item?.id}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </span>
          <span className="flex-1 flex flex-col">
            <label>Week</label>
            <Select
              onPopupScroll={getPrevDateRange}
              onChange={onDateChange}
              defaultValue={`${dateRange.start}/${dateRange.end}`}
            >
              {dateRange.rangeOptions.map((item) => (
                <Select.Option value={item?.value}>{item?.label}</Select.Option>
              ))}
            </Select>
          </span>
        </div>
      </div>

      {timesheet.loading ? (
        <div className="bg-white">
          <TextShimmer
            data={{
              line: 10,
              gap: 15,
            }}
          />
        </div>
      ) : (
        timesheet.data.map((item, index) => (
          <div key={index}>
            {item?.worksites?.map((timesheetItem, index) => (
              <div key={index}>
                {timesheetItem?.days?.map((worksite, siteIndex) => (
                  <div className="bg-white m-2">
                    <Collapse
                      className="border-none p-0 m-[0px] "
                      ghost
                      accordion
                      expandIcon={({ isActive }) =>
                        isActive ? <FaAngleUp /> : <FaAngleDown />
                      }
                    >
                      <Panel
                        ghost
                        header={
                          <div className="inline-flex gap-8">
                            <p className="font-medium text-base">
                              {timesheetItem.job_no}
                            </p>
                            <p className="font-medium text-base">{item.name}</p>
                            <div
                              className="font-medium text-base"
                              key={siteIndex}
                            >
                              {worksite.day.charAt(0).toUpperCase() +
                                worksite.day.slice(1)}
                            </div>
                            <div
                              className="font-medium text-base"
                              key={siteIndex}
                            >
                              {worksite.date}
                            </div>
                          </div>
                        }
                        style={{
                          margin: "5px",
                        }}
                        className="border-none p-0 m-[0px] "
                      >
                        <th>
                          <div
                            className=" font-semibold"
                            style={{
                              color: "#FE650C",
                              fontSize: "20px",
                              marginBottom: "20px",
                            }}
                          >
                            <div>{timesheetItem.name}</div>
                          </div>
                        </th>

                        <table className="text-center font-sm w-full">
                          <thead className="timesheet-detailed-table-head-container">
                            <tr className="timesheet-detailed-table-main-head">
                              <th>
                                <div className="font-semibold">
                                  Emp. Co./Last Name
                                </div>
                              </th>
                              <th>
                                <div className="font-semibold">
                                  Emp. First Name
                                </div>
                              </th>
                              <th>
                                <div className="font-semibold">
                                  Payroll Category
                                </div>
                              </th>
                              <th>
                                <div className="font-semibold">Job </div>
                              </th>
                              <th>
                                <div className="font-semibold">Date</div>
                              </th>
                              <th>
                                <div className="font-semibold">Unit</div>
                              </th>
                              <th>Emp. Card ID</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody className="timesheet-detailed-table-body">
                            {worksite?.worksite_timesheet_data?.length > 0 ? (
                              worksite?.worksite_timesheet_data?.map(
                                (data, dataIndex) => (
                                  <tr
                                    className="border-b border-b-[#E9E9E9]"
                                    key={dataIndex}
                                  >
                                    <td className="w-10 text-left">
                                      {
                                        data.worker_details[0]?.users[0]
                                          ?.last_name
                                      }
                                    </td>
                                    <td className="w-10 text-left">
                                      {data.worker_details[0]?.users[0]?.name}
                                    </td>
                                    <td>
                                      {data.payroll_category_name}
                                      {/* {String(data.started).replace(".", ":")} -{" "}
                                      {data.finished !== 0
                                        ? String(data.finished).replace(
                                            ".",
                                            ":"
                                          )
                                        : "Still working"} */}
                                    </td>

                                    <td>
                                      {timesheetItem.job_no}
                                      {/* {data.worksite_timesheet_workdone[0]
                                        ?.hourly_rate_tasks_estimations[0]
                                        ?.hourly_rate_task[0]?.name || "-"} */}
                                    </td>

                                    <td>
                                      {moment?.(
                                        data?.timesheet_created_at
                                      )?.format("DD-MM-YYYY")}
                                    </td>
                                    <td>
                                      {data?.unit}
                                      {/* {data.finished && data.started
                                        ? (
                                            data.finished - data.started
                                          ).toFixed(2)
                                        : "N/A"} */}
                                    </td>

                                    <td>
                                      {
                                        data.worker_details[0]?.users[0]
                                          ?.employee_id
                                      }
                                    </td>
                                    {/* <td>{data.dollar.cost || "-"}</td>
                                    <td>{data.break || "-"}</td>
                                    <td>{data.timesheet_status}</td> */}
                                    <td
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/admin/worksite/edit-timesheet-details/${data.worksite_id}/${data.worksite_timesheet_id}/${data._id}`
                                        )
                                      }
                                    >
                                      <AiOutlineForm
                                        style={{
                                          position: "relative",
                                          top: "10px",
                                        }}
                                      />
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">
                                  No data found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Panel>
                    </Collapse>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
};

export default TimesheetPayroll;
